import { defineNuxtRouteMiddleware } from "nuxt/app";
export default defineNuxtRouteMiddleware(async (to, from) => {
  const { onResult } = await useQuery(whoAmI);

  onResult((queryResult) => {
    if (queryResult.data.me.role.title !== "CLIENT") {
      return navigateTo("/");
    }
  });
});

const whoAmI = gql`
  query me {
    me {
      id
      role {
        title
      }
    }
  }
`;
